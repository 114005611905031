export enum ResourceType {
  School = 'SCHOOL',
  SchoolDistrict = 'SCHOOL_DISTRICT',
  Partner = 'PARTNER',
}

export enum Role {
  Admin = 'ADMIN',
  PartnerManager = 'PARTNER_MANAGER',
  SchoolDistrictManager = 'SCHOOL_DISTRICT_MANAGER',
  Manager = 'MANAGER',
  Coach = 'COACH',
  Student = 'STUDENT',
  DemoStudent = 'DEMO_STUDENT',
}
