import { ApolloLink, NextLink, Operation } from '@apollo/client/link/core';
import { IS_DEVELOPMENT } from '@/lib/constants';
import { logger } from '@/logger';

class CsrfToken extends ApolloLink {
  logger = logger.getLogger('CsrfTokenLink');
  HEADER_NAME = 'X-Csrf-Token';
  csrfToken: string | undefined;

  constructor() {
    super();
    this.csrfToken = undefined;
  }

  request(operation: Operation, forward: NextLink) {
    if (this.csrfToken) {
      operation.setContext({
        headers: { [this.HEADER_NAME]: this.csrfToken },
      });

      if (IS_DEVELOPMENT) {
        this.logger.trace(`Set '${this.HEADER_NAME}' to '${this.csrfToken}'`);
      }
    }

    return forward(operation).map((data) => {
      const response = operation.getContext().response as Response;
      const value = response.headers.get(this.HEADER_NAME);

      if (value) {
        this.csrfToken = value;

        if (IS_DEVELOPMENT) {
          this.logger.trace(
            `Received '${this.HEADER_NAME}' as '${this.csrfToken}'`,
          );
        }
      }

      return data;
    });
  }

  getHeaders(): HeadersInit {
    return { [this.HEADER_NAME]: this.csrfToken || '' };
  }
}

export const CsrfTokenLink = new CsrfToken();
