import { acceptHMRUpdate, defineStore } from 'pinia';
import { IS_PRODUCTION } from '@/lib/constants';
import { convertKeysFromQwerty, getKey } from '@/lib/keyboard-layout';
import { lookupLocaleKeyboardLayout } from '@/lib/locale';
import { Locale } from '@/types/export';
import type { LocaleKeyboardLayoutLookup } from '@/types/export';
import { GqlKeyboardLayout } from '@/types/graphql';

type State = {
  isProduction: boolean;
  keyboardLayout: GqlKeyboardLayout;
  locale: Locale;
};

export const useMainStore = defineStore('main', {
  state: (): State => ({
    isProduction: IS_PRODUCTION,
    keyboardLayout: GqlKeyboardLayout.QWERTY,
    locale: Locale.nl,
  }),
  actions: {
    reset(): void {
      this.$reset();
    },
    getFullKey(key: string): Key {
      return getKey(this.keyboardLayout, key);
    },
    convertKeys<T extends string | string[]>(keys: T): T {
      const separator = '';

      const isArray = Array.isArray(keys);

      const allKeys = isArray ? keys : keys.split(separator);

      const convertedKeys = convertKeysFromQwerty(this.keyboardLayout, allKeys);

      return (isArray ? convertedKeys : convertedKeys.join(separator)) as T;
    },
    lookupContent<T>(lookup: LocaleKeyboardLayoutLookup<T>): T {
      return lookupLocaleKeyboardLayout<T>(
        this.locale,
        this.keyboardLayout,
        lookup,
      );
    },
  },
  persist: true,
});

if (module.hot) {
  module.hot.accept(acceptHMRUpdate(useMainStore, module.hot));
}
