// eslint-disable-next-line no-restricted-imports
import logger from 'loglevel';
import { z } from 'zod';
import { prefixLogger } from './prefix';

const level = z
  .enum(['trace', 'debug', 'info', 'warn', 'error', 'silent'])
  .default('info')
  .parse(process.env.VUE_APP_LOG_LEVEL);
logger.setDefaultLevel(level);
logger.setLevel(level);

prefixLogger(logger);

export { logger };
