import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/vue';

export const SentryRequestIdLink = setContext((_, { headers }) => {
  const transactionId = Math.random().toString(36).substr(2, 9);

  Sentry.configureScope((scope) =>
    scope.setTag('transaction_id', transactionId),
  );

  return {
    headers: {
      ...headers,
      'x-request-id': transactionId,
    },
  };
});
