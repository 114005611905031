import { RetryLink as Link } from '@apollo/client/link/retry';

export const RetryLink = new Link({
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
  delay: {
    initial: 1_000,
    jitter: true,
    max: Infinity,
  },
});
