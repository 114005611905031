import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import '@/lib/mixins';
import '@/components';
import '@/modal';
import '@/scroll-to';
import VueRouter from 'vue-router';
import { apollo } from '@/apollo';
import App from '@/App.vue';
import { setupGTM } from '@/gtm';
import { i18n } from '@/i18n';
import { router } from '@/router';
import { setupSentry } from '@/sentry';

/** START -- Pinia */
Vue.use(PiniaVuePlugin);
export const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
/** END -- Pinia */

Vue.use(VueRouter);
Vue.use(VueMeta);

setupSentry(router);
setupGTM(router);

Vue.use(PortalVue);

Vue.config.productionTip = false;

new Vue({
  pinia,
  router,
  i18n,
  apolloProvider: apollo,
  render: (h) => h(App),
}).$mount('#app');
