<template>
  <div />
</template>

<script>
import IdleJs from 'idle-js';
import { mapStores } from 'pinia';
import ModalMessage from '@/components/ModalMessage';
import { logger } from '@/logger';
import { useAuthStore } from '@/stores/auth';

export default {
  props: {
    // time before the idle modal is shown in seconds
    timeout: {
      type: Number,
      default: 60,
    },
    // time modal is shown before logout in seconds
    modalTimeout: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      idle: null,
    };
  },
  mounted() {
    this.idle = new IdleJs({
      idle: this.timeoutInMs,
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter

      keepTracking: true,
      onIdle: () => {
        this.showIdleMessage();
      },
    });
  },
  computed: {
    ...mapStores(useAuthStore),
    timeoutInMs() {
      return this.timeout * 1000;
    },
    modalTimeoutInMs() {
      return this.modalTimeout * 1000;
    },
  },
  watch: {
    $route: {
      handler() {
        if (!this.$route.meta.allowUnauthorized) {
          this.idle.stop().reset().start();
        } else {
          this.idle.stop().reset();
        }
      },
      deep: true,
    },
  },
  methods: {
    showIdleMessage() {
      if (this.$route.meta.allowUnauthorized) {
        return;
      }

      this.idle.stop();

      const timeout = setTimeout(async () => {
        this.$modal.hide('idleModal');
        logger.warn('Logout due to inactivity');
        this.$modal.show(ModalMessage, {
          title: 'Let op',
          bodyText: 'Je bent automatisch uitgelogd door inactiviteit',
          primaryText: 'OK',
        });

        await this.authStore.logout();
        this.$router.push({ name: 'Login' });
      }, this.modalTimeoutInMs);

      this.$modal.show(
        ModalMessage,
        {
          title: 'Let op',
          bodyText:
            'Je bent al een tijdje inactief. Je zal in een ogenblik worden uitgelogd.',
          primaryText: 'Ik ben er nog!',
          primaryIcon: false,
          primaryCallback: () => {
            clearTimeout(timeout);
            this.idle.stop().reset().start();
          },
        },
        {
          name: 'idleModal',
          width: 600,
          height: 'auto',
        },
      );
    },
  },
};
</script>

<style></style>
